<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <!-- <v-select
          dense
          solo
          color="blue"
          hide-details
          label="select specialization"
          background-color="#ffffed"
        ></v-select> -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" @click="lists()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-card flat rounded="0">
          <v-card-text>
            <v-data-table
              :loading="loading"
              loader-height="2"
              :headers="headers"
              :items="certified"
              :items-per-page="8"
              :footer-props="{ itemsPerPageOptions: [8, 16, 24] }"
            >
              <template v-slot:item.fullname="{ item }">
                {{ item.salute | capitalize }} {{ item.first_name }}
                {{ item.last_name }}
              </template>
              <template v-slot:item.verified="{ item }">
                <v-icon :color="item.verified ? 'blue' : 'red'">
                  {{ item.verified ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template>

              <template v-slot:item.actions="{ item }">
                <!-- :disabled="
                    activable(item.profession_expiry, item.registration_number)
                  " -->
                <v-btn
                  block
                  text
                  x-small
                  color="primary"
                  @click.stop="$set(activateDialog, item.id, true)"
                >
                  activate
                </v-btn>
                <SubscribriberActivate
                  v-if="activateDialog[item.id]"
                  :id="item.id"
                  :fname="item.first_name"
                  :lname="item.last_name"
                  :verify="item.verified"
                  :expire="item.profession_expiry"
                  :activateDialog="activateDialog[item.id]"
                  @closeAct="closeAct"
                  @submitAct="submitAct"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-container>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
      timeout="-1"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils";
import SubscribriberActivate from "../../../components/subscribers/activateSubscriber.vue";

export default {
  mixins: [Utils],
  components: { SubscribriberActivate },
  data() {
    return {
      loading: false,
      search: "",
      breadcrumb: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "admin.dash" },
        },
        {
          text: "licence verifications",
          disabled: true,
        },
      ],
      activateDialog: {},
      headers: [
        { text: "full name", value: "fullname" },
        { text: "subscriber type", value: "subscriber_type" },
        { text: "professional_name", value: "professional_name" },
        { text: "registration number", value: "registration_number" },
        { text: "verified", align: "center", value: "verified" },
        { text: "actions", value: "actions", align: "center" },
      ],
      certified: [],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
    };
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let self = this;
      Restful.mySubscriber
        .certified(false)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.certified = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    activable(exp, reg) {
      console.log("exp", exp);
      console.log("reg", reg);
      if (exp === null) {
        return true;
      } else {
        return false;
      }
    },
    closeAct(p) {
      this.$set(this.activateDialog, p.id, p.state);
    },
    submitAct(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.mySubscriber
        .activate(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.activateDialog, p.id, p.state);
    },
  },
};
</script>
