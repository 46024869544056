<template>
  <v-dialog v-model="activateDialog" persistent max-width="700">
    <v-card :loading="laoding" loader-height="2">
      <v-card-title>activate {{ fname }} {{ lname }} </v-card-title>
      <v-card-text>
        <v-list subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Certification Expiry </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              {{ expire | myDate }}
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Active </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-switch
                v-model="entry.verified"
                :label="`  .   ${entry.verified ? ' Yes' : ' No'}`"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils.js";
export default {
  props: {
    activateDialog: Boolean,
    id: { type: Number, default: 0 },
    fname: { type: String, default: null },
    lname: { type: String, default: null },
    expire: { type: String },
    verify: { type: Boolean },
  },
  mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      laoding: false,
      entry: {
        verified: false,
      },
    };
  },
  watch: {
    verify: function (a) {
      this.entry.verified = a;
    },
  },
  created() {
    this.entry.verified = this.verify;
  },
  methods: {
    closeDialog() {
      let payload = { id: this.id, state: false };
      this.$emit("closeAct", payload);
    },
    submit() {
      let verified = this.entry.verified;
      let data = { verified };

      let payload = { id: this.id, state: false, data: data };
      this.$emit("submitAct", payload);
    },
  },
};
</script>
